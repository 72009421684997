import React from 'react';

import { useViewport } from '../../utilities/useViewport';
import { Button } from '../Button/Button';

type CloseButtonProps = {
  'aria-label'?: React.ReactNode;
  onClick?: React.ReactEventHandler;
};

export const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ 'aria-label': ariaLabel = 'Close', onClick, ...remaining }, ref) => {
    const { breakpoint } = useViewport();
    return (
      <Button
        type="button"
        onClick={onClick}
        arrangement="hidden-label"
        icon="cross"
        size={breakpoint !== 'desktop' ? 'medium' : 'small'}
        variant="secondary"
        {...remaining}
        ref={ref}
      >
        {ariaLabel}
      </Button>
    );
  },
);

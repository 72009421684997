import React from 'react';

import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const SkimmerHeaderContainer = styled(ShellHeader);

export function SkimmerHeader(props: ShellHeaderProps) {
  return <SkimmerHeaderContainer {...props} />;
}

const SkimmerContentContainer = styled(ShellContent);

export function SkimmerContent(props: ShellContentProps) {
  return <SkimmerContentContainer {...props} />;
}

const SkimmerFooterContainer = styled(ShellFooter);

export function SkimmerFooter(props: ShellFooterProps) {
  return <SkimmerFooterContainer {...props} />;
}

const SkimmerContainer = styled(Shell, {
  gridArea: 'skimmer',

  '&:not(:last-child)': {
    strokeRight: colors.strokeNeutralLight,
  },

  [darkThemeSelector]: {
    '&:not(:last-child)': {
      strokeRight: colors.strokeNeutralDark,
    },
  },

  '@mobile': {
    width: '100%',
    minHeight: '$160',
    maxHeight: '$360',
  },

  '@notMobile': {
    width: '$360',
  },
});

export type SkimmerProps = ShellProps & {
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement | null>;
};

export function Skimmer({ children, ref, ...props }: SkimmerProps) {
  return (
    <SkimmerContainer ref={ref} {...props}>
      {children}
    </SkimmerContainer>
  );
}

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type MinHeightTypes = 'full' | 'screen' | SpacingScale;
export type MinHeightProps = { minHeight?: MinHeightTypes };

export const minHeightCSS = (minHeight?: MinHeightTypes): CSS => {
  if (minHeight === 'full') {
    return { minHeight: '100%' };
  }

  if (minHeight === 'screen') {
    return { minHeight: '100dvh' };
  }

  if (minHeight != null) {
    return {
      minHeight: `$${minHeight}`,
    };
  }

  return {};
};

import React, { useCallback } from 'react';

import { Tooltip } from '../../components/Tooltip/Tooltip';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { copyTextToClipboard } from '../../utilities/clipboard';
import { defer } from '../../utilities/defer';
import { useTimedToggle } from '../../utilities/useTimedToggle';

const CopyBoxValue = styled('span', {
  position: 'relative',
  zIndex: '2',
  display: 'flex',

  // Make sure the value inherits the font properties from the text-component further up the DOM.
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',

  variants: {
    isClicked: {
      true: {
        color: colors.headingBrandLight,
        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
    },
    wrap: {
      wrap: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
      },
      nowrap: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

const CopyBoxBoundary = styled('div', {
  position: 'absolute',
  zIndex: 1,
  strokeAll: colors.transparent,
  transition: transitions.control,
});

const CopyBoxContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  maxWidth: '100%',
  cursor: 'pointer',
  [selectors.hover]: {
    [`${CopyBoxBoundary}`]: {
      backgroundColor: colors.bgNeutralLight,
      strokeAll: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        backgroundColor: colors.bgNeutralDark,
        strokeAll: colors.strokeNeutralDark,
      },
    },
  },

  [selectors.focus]: {
    outline: 'none',

    [`${CopyBoxBoundary}`]: {
      outline: 'none',
      boxShadow: shadows.focusRingLight,

      [darkThemeSelector]: {
        boxShadow: shadows.focusRingDark,
      },
    },
  },

  variants: {
    isClicked: {
      true: {
        '&, &:hover, &:focus': {
          [`${CopyBoxBoundary}`]: {
            backgroundColor: colors.bgBrandLight,
            strokeAll: colors.strokeBrandLight,
            [darkThemeSelector]: {
              backgroundColor: colors.bgBrandDark,
              strokeAll: colors.strokeBrandDark,
            },
          },
        },
        [`${CopyBoxBoundary}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
      false: {},
    },
    relation: {
      stacked: {},
      standalone: {
        [`${CopyBoxBoundary}`]: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: colors.bgNeutralLight,
          strokeAll: colors.strokeNeutralLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgNeutralDark,
            strokeAll: colors.strokeNeutralDark,
          },
        },
      },
    },
    size: {
      large: {
        [`${CopyBoxBoundary}`]: {
          borderRadius: '$10',
        },
      },
      small: {
        [`${CopyBoxBoundary}`]: {
          borderRadius: '$8',
        },
      },
    },
  },

  compoundVariants: [
    {
      size: 'large',
      relation: 'stacked',
      css: {
        [`${CopyBoxBoundary}`]: {
          top: '-8px',
          right: '-12px',
          bottom: '-8px',
          left: '-12px',
        },
      },
    },
    {
      size: 'small',
      relation: 'stacked',
      css: {
        [`${CopyBoxBoundary}`]: {
          top: '-2px',
          right: '-6px',
          bottom: '-2px',
          left: '-6px',
        },
      },
    },
    {
      size: 'large',
      relation: 'standalone',
      css: {
        padding: '$8 $12',
      },
    },
    {
      size: 'small',
      relation: 'standalone',
      css: {
        padding: '$2 $6',
      },
    },
  ],
});

export type CopyBoxProps = {
  /**
   * Description of the action taken when clicking the component. **Required** for accessibility support.
   */
  'aria-label': React.ReactNode;
  /**
   * Displayed as the contents of the component.
   */
  children: React.ReactNode;
  /**
   * Determines how the component is positioned with its surroundings.
   */
  relation?: 'stacked' | 'standalone';
  /**
   * Set which sizing you want.
   */
  size?: 'large' | 'small';
  /**
   * Value to copy to the clipboard when the CopyBox is clicked, defaults to the value of `children`.
   */
  value?: React.ReactNode;
  /**
   * Set whether the contents should wrap or not.
   */
  wrap?: 'nowrap' | 'wrap';
};

export function CopyBox({
  'aria-label': ariaLabel = 'Copy to clipboard',
  children,
  relation = 'stacked',
  size = 'small',
  value,
  wrap = 'wrap',
  ...props
}: CopyBoxProps) {
  const { value: isClicked, hide, show } = useTimedToggle();
  const valueFallback = value ? `${value}` : `${children}`;
  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      await copyTextToClipboard(valueFallback);
      hide();
      await defer(50);
      show();
    },
    [valueFallback, hide, show],
  );

  return (
    <Tooltip asChild contents={ariaLabel}>
      <CopyBoxContainer
        {...props}
        role="button"
        tabIndex={0}
        aria-label={`${ariaLabel}`}
        onClick={handleClick}
        isClicked={isClicked}
        relation={relation}
        size={size}
      >
        <CopyBoxValue isClicked={isClicked} wrap={wrap}>
          {children}
        </CopyBoxValue>
        <CopyBoxBoundary />
      </CopyBoxContainer>
    </Tooltip>
  );
}

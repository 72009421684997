import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const SmallBase = {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

export const SmallSansSizes = {
  '&': {
    '@notDesktop': {
      fontSize: '$14',
      lineHeight: '$20',
    },
    '@desktop': {
      fontSize: '$12',
      lineHeight: '$16',
    },
  },
};

export const SmallMonoSizes = {
  '&': {
    '@notDesktop': {
      fontSize: '$13',
      lineHeight: '$20',
    },
    '@desktop': {
      fontSize: '$11',
      lineHeight: '$16',
    },
  },
};

export const Small = styled(Text, SmallBase, {
  variants: {
    family: {
      regular: SmallSansSizes,
      monospace: SmallMonoSizes,
    },
  },
  defaultVariants: {
    family: 'regular',
  },
});

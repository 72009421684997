export const breakpoints = {
  width: {
    small: 768,
    medium: 768,
    large: 1134,
    extraLarge: 1280,
  },
  height: {
    small: 744,
  },
};

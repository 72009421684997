import type { CSS } from '../../stitches.config';

export type LineHeightProp = 12 | 14 | 16 | 20 | 24 | 28 | 32;

export const lineHeightCSS = (lineHeight?: LineHeightProp): CSS =>
  lineHeight != null
    ? {
        lineHeight: `$${lineHeight}`,
      }
    : {};

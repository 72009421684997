import type { CSS as StitchesCSS, PropertyValue, ScaleValue } from '@stitches/react';
import { createStitches } from '@stitches/react';

import { breakpoints } from './common/breakpoints';
import { palette } from './common/colors';
import { fontFamilies, makeFontString } from './common/fonts';
import { gradients } from './common/gradients';
import { shadows as commonShadows } from './common/shadows';
import { type SpacingValue, SPACING_VALUE } from './common/sizing';

const SCALE_BASE_PX = 16;

const rem = (px: number): string => `${px / SCALE_BASE_PX}rem`;
const mappings: [SpacingValue, string][] = SPACING_VALUE.map((val) => [val, rem(val)]);
export const spacings = Object.fromEntries(mappings) as Record<SpacingValue, string>;

export const theme = createStitches({
  theme: {
    colors: { ...palette, ...gradients },
    space: spacings,
    sizes: spacings,
    fonts: {
      mono: makeFontString(fontFamilies.mono),
      sans: makeFontString(fontFamilies.sans),
      title: makeFontString(fontFamilies.title),
    },
    shadows: { ...commonShadows },
    fontWeights: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '500',
      extraBold: '600',
    },
    radii: {
      0: rem(0),
      2: rem(2),
      4: rem(4),
      6: rem(6),
      8: rem(8),
      10: rem(10),
      12: rem(12),
      14: rem(14),
      16: rem(16),
      18: rem(18),
      20: rem(20),
    },
    lineHeights: {
      12: rem(12),
      14: rem(14),
      16: rem(16),
      18: rem(18),
      20: rem(20),
      24: rem(24),
      28: rem(28),
      32: rem(32),
    },
    fontSizes: {
      9: rem(9),
      10: rem(10),
      11: rem(11),
      12: rem(12),
      13: rem(13),
      14: rem(14),
      15: rem(15),
      16: rem(16),
      17: rem(17),
      18: rem(18),
      20: rem(20),
      22: rem(22),
      24: rem(24),
      26: rem(26),
      28: rem(28),
    },
  },
  utils: {
    _transformParts: () => ({
      $$translateX: 0,
      $$translateY: 0,
      $$skewX: 0,
      $$skewY: 0,
      $$scaleX: 1,
      $$scaleY: 1,
      $$rotate: 0,
      transform:
        'translateX($$translateX) translateY($$translateY) rotate($$rotate) skewX($$skewX) skewY($$skewY) scaleX($$scaleX) scaleY($$scaleY)',
    }),
    translateY: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateY: value,
    }),
    translateX: (value: PropertyValue<'translate'>) => ({
      _transformParts: true,
      $$translateX: value,
    }),
    paddingX: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    truncate: () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    borderRadiusTop: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderTopRightRadius: value,
    }),
    borderRadiusBottom: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderBottomLeftRadius: value,
      borderBottomRightRadius: value,
    }),
    borderRadiusLeft: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopLeftRadius: value,
      borderBottomLeftRadius: value,
    }),
    borderRadiusRight: (value: PropertyValue<'borderTopLeftRadius'>) => ({
      borderTopRightRadius: value,
      borderBottomRightRadius: value,
    }),
    hStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: value,
    }),
    vStack: (value: PropertyValue<'gap'>) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: value,
    }),
    strokeAll: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0 0 0.5px ${value}, inset 0 0 0 0.5px ${value}`,
    }),
    strokeAllThick: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0 0 1px ${value}, inset 0 0 0 1px ${value}`,
    }),
    strokeTopBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 ${value}, inset 0 -0.5px 0 ${value}, 0 0.5px 0 ${value}, inset 0 0.5px 0 ${value}`,
    }),
    strokeTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}`,
    }),
    strokeBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}`,
    }),
    strokeLeftRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `-0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeTopLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeTopRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeBottomLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeBottomRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoTop: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    strokeNoRight: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoBottom: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}, -0.5px 0 0 0 ${value}, inset 0.5px 0 0 0 ${value}`,
    }),
    strokeNoLeft: (value: ScaleValue<'colors'> | string) => ({
      boxShadow: `0 -0.5px 0 0 ${value}, inset 0 0.5px 0 0 ${value}, 0 0.5px 0 0 ${value}, inset 0 -0.5px 0 0 ${value}, 0.5px 0 0 0 ${value}, inset -0.5px 0 0 0 ${value}`,
    }),
    widthAll: (value: PropertyValue<'width'>) => ({
      width: value,
      maxWidth: value,
      minWidth: value,
    }),
    heightAll: (value: PropertyValue<'height'>) => ({
      height: value,
      maxHeight: value,
      minHeight: value,
    }),
  },
  media: {
    // Width
    sm: `(min-width: ${breakpoints.width.small}px)`,
    md: `(min-width: ${breakpoints.width.medium}px)`,
    lg: `(min-width: ${breakpoints.width.large}px)`,
    xl: `(min-width: ${breakpoints.width.extraLarge}px)`,
    maxSm: `(max-width: ${breakpoints.width.small - 1}px)`,
    maxMd: `(max-width: ${breakpoints.width.medium - 1}px)`,
    maxLg: `(max-width: ${breakpoints.width.large - 1}px)`,
    maxXl: `(max-width: ${breakpoints.width.extraLarge - 1}px)`,

    // Width presets
    mobile: `(max-width: ${breakpoints.width.small}px)`,
    notMobile: `(min-width: ${breakpoints.width.small + 1}px)`,
    tablet: `(min-width: ${breakpoints.width.small + 1}px) and (max-width: ${breakpoints.width.large - 1}px)`,
    notDesktop: `(max-width: ${breakpoints.width.large - 1}px)`,
    desktop: `(min-width: ${breakpoints.width.large}px)`,

    // Height
    short: `(max-height: ${breakpoints.height.small}px)`,
    tall: `(min-height: ${breakpoints.height.small + 1}px)`,

    prefersDark: '(prefers-color-scheme: dark)',
    prefersLight: '(prefers-color-scheme: light)',
  },
});

export const {
  styled,
  css,
  keyframes,
  globalCss,
  theme: { fonts, shadows, colors, fontWeights },
} = theme;

export type CSS = StitchesCSS<typeof theme.config>;

export const darkThemeSelector = '.m-dark &';
export const lightThemeSelector = '.m-light &';

export const activeThemeClassName = 'atto-active-theme';
export const activeThemeSelector = `.${activeThemeClassName} &`;

export const focusVisibleClassName = 'atto-focus-visible';
export const focusVisibleSelector = `.${focusVisibleClassName} &`;

export const safariOnlyCSS = (properties: CSS) => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      '&': properties,
    },
  },
});

export const firefoxOnlyCSS = (properties: CSS) => ({
  '@-moz-document url-prefix()': {
    '&': properties,
  },
});

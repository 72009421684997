import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small } from '../../text/Small';

export const SummaryTable = styled('div', {
  display: 'table',
  padding: `0 $${sizing.primary}`,
  width: '100%',
});

export const SummaryTableCell = styled('div', Small, {
  display: 'table-cell',
  overflow: 'hidden',
  padding: '0 $6',
  maxWidth: 0,
  height: '$40',
  verticalAlign: 'middle',
  borderTop: '1px solid',
  borderTopColor: colors.strokeNeutralLight,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: colors.gray600,
  [darkThemeSelector]: {
    borderTopColor: colors.strokeNeutralDark,
    color: colors.gray100,
  },
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
});

export const SummaryTableRow = styled('div', {
  display: 'table-row',
  '&:first-child > div': {
    borderTop: 0,
  },
  '&:hover > div': {
    cursor: 'pointer',
    color: colors.gray700,
    [darkThemeSelector]: {
      color: colors.gray50,
    },
  },
});

const SummaryTableArrowIcon = styled(Icon, {
  width: '$8',
  height: '$8',
  color: colors.gray400,
});

export function SummaryTableArrow() {
  return (
    <SummaryTableCell
      style={{ padding: 0, width: '8px', minWidth: '8px', maxWidth: '8px' }}
      aria-hidden
    >
      <SummaryTableArrowIcon icon="chevron-right" />
    </SummaryTableCell>
  );
}

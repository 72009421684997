import type { SVGAttributes } from 'react';
import { BrandingSVG } from 'atto-svgs';
import React from 'react';

import type { SpacingValue } from '../../common/sizing';
import { styled } from '../../stitches.config';

export type BrandingSize = SpacingValue;

export const Brandings = {
  insignia: BrandingSVG.Insignia,
};

export const BrandingValues = Object.keys(Brandings);
export type BrandingName = keyof typeof Brandings;
export const BrandingNames = Object.keys(Brandings) as BrandingName[];

export interface BrandingProps {
  /**
   * Set which icon to display.
   */
  icon: BrandingName;
  /**
   * Set a size of the component.
   */
  size?: BrandingSize;
}

const BaseSVG = styled('svg', {
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
});

export function Branding({ icon, size = 24, ...remaining }: BrandingProps) {
  const NamedBranding = Brandings[icon] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedBranding) {
    // eslint-disable-next-line no-console
    console.error(`Missing branding named ${icon}`);
    return null;
  }

  return <BaseSVG as={NamedBranding} aria-hidden width={size} height={size} {...remaining} />;
}

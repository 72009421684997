import type { CSS } from '@stitches/react';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { mergeProps, useFocus } from 'react-aria';
import useMeasure from 'react-use-measure';

import type { IconName } from '../../assets/Icon/Icon';
import type { ControlSize } from '../../controls/shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { ControlSizeProvider, useControlSize } from '../../common/control_size';
import { Button } from '../../controls/Button/Button';
import { selectors, transitions } from '../../controls/shared/styles';
import {
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';

export const ClearInputButton = styled(Button, {
  display: 'none',
});

export const BaseInputContainer = styled('label', {
  $$inputColor: colors.gray800,
  $$iconColor: colors.gray700,
  $$placeholderColor: colors.gray500,
  $$sidecarColor: colors.gray700,

  position: 'relative',
  display: 'flex',
  minWidth: 'min-content',
  background: colors.bgApplicationLight,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  '&:not([data-is-focused]):hover': {
    strokeAll: fade(palette.controlStrokeBaseLight, 0.18),

    [darkThemeSelector]: {
      strokeAll: fade(palette.controlStrokeBaseDark, 0.66),
    },
  },

  [selectors.hover]: {
    [`& ${ClearInputButton}`]: {
      display: 'block',
    },
  },

  '&[data-is-focused]': {
    boxShadow: shadows.fieldFocusedLight,
  },

  [darkThemeSelector]: {
    $$inputColor: colors.gray50,
    $$iconColor: colors.gray100,
    $$placeholderColor: colors.gray300,
    $$sidecarColor: colors.gray100,
    background: colors.bgApplicationDark,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),
    colorScheme: 'dark',

    '&[data-is-focused]': {
      boxShadow: shadows.fieldFocusedDark,
    },
  },
  variants: {
    isNested: {
      true: {
        background: colors.transparent,
        strokeAll: colors.transparent,

        [darkThemeSelector]: {
          background: colors.transparent,
          strokeAll: colors.transparent,
        },
      },
      false: {},
    },
    type: {
      none: {},
      text: {},
      tel: {},
      url: {},
      email: {},
      numeric: {},
      decimal: {},
      search: {},
    },
    size: {
      'x-small': {
        minHeight: '$20',
        borderRadius: '$6',
        fontSize: '$12',

        $$inputPadding: '$space$2 $space$6',
        $$sidecarGap: '$space$4',
        $$iconSize: '$space$12',
        $$lineHeight: '$space$16',
      },
      small: {
        '@notDesktop': {
          minHeight: '$28',
          borderRadius: '$10',

          $$sidecarGap: '$space$6',
          $$inputPadding: '$space$2 $space$8',
          $$iconSize: '$space$14',
        },

        '@desktop': {
          minHeight: '$24',
          borderRadius: '$8',
          fontSize: '$12',

          $$sidecarGap: '$space$4',
          $$inputPadding: '$space$2 $space$6',
          $$iconSize: '$space$12',
          $$lineHeight: '$space$16',
        },
      },
      medium: {
        '@notDesktop': {
          minHeight: '$32',
          borderRadius: '$10',

          $$sidecarGap: '$space$6',
          $$inputPadding: '$space$4 $space$10',
          $$iconSize: '$space$14',
        },

        '@desktop': {
          minHeight: '$28',
          borderRadius: '$8',
          fontSize: '$14',

          $$sidecarGap: '$space$4',
          $$inputPadding: '$space$4 $space$8',
          $$iconSize: '$space$12',
          $$lineHeight: '$space$20',
        },
      },
      large: {
        minHeight: '$36',
        borderRadius: '$8',
        fontSize: '$14',

        $$inputPadding: '$space$8 $space$12',
        $$sidecarGap: '$space$6',
        $$iconSize: '$space$14',
        $$lineHeight: '$space$20',
      },
      'x-large': {
        minHeight: '$44',
        borderRadius: '$8',

        $$inputPadding: '$space$10 $space$14',
        $$sidecarGap: '$space$6',
        $$iconSize: '$space$16',
        $$lineHeight: '$space$24',
        fontSize: '$16',
      },
    },
    invalid: {
      true: {
        strokeAll: fade(palette.controlStrokeBaseErrorLight, 0.8),

        [darkThemeSelector]: {
          strokeAll: fade(palette.controlStrokeBaseErrorDark, 0.8),
        },
      },
      false: {},
    },
    isDisabled: {
      true: {
        background: colors.gray50,
        strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
        opacity: 0.5,
        pointerEvents: 'none',

        [darkThemeSelector]: {
          background: colors.gray900,
          strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      isNested: true,
      invalid: false,
      css: {
        '&:not([data-is-focused]):hover': {
          strokeAll: colors.transparent,

          [darkThemeSelector]: {
            strokeAll: colors.transparent,
          },
        },
      },
    },
    {
      size: 'small',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'medium',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'large',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
    {
      size: 'x-large',
      type: 'search',
      css: {
        borderRadius: '99em',
      },
    },
  ],
});

export const Input = styled('input', {
  display: 'block',
  width: '100%',
  padding: '$$inputPadding',
  background: 'none',
  border: 'none',
  outline: 'none',
  color: '$$inputColor',
  lineHeight: '$$lineHeight',
  truncate: true,

  [darkThemeSelector]: {
    colorScheme: 'dark',
  },

  '&::placeholder': {
    color: '$$placeholderColor',
  },

  /* clears the 'X' from Internet Explorer */
  '&[type="search"]::-ms-clear, &[type="search"]::-ms-reveal': {
    display: 'none',
    width: 0,
    height: 0,
  },

  /* clears the 'X' from Chrome */
  '&[type="search"]::-webkit-search-decoration, &[type="search"]::-webkit-search-cancel-button, &[type="search"]::-webkit-search-results-button, &[type="search"]::-webkit-search-results-decoration':
    {
      display: 'none',
    },
});

const Sidecar = styled('div', {
  hStack: '$$sidecarGap',
  position: 'absolute',
  top: 0,
  bottom: 0,
  padding: '$$inputPadding',
  color: '$$sidecarColor',
});

const Prefix = styled(Sidecar, {
  left: 0,
});

const Suffix = styled(Sidecar, {
  right: 0,
});

const StyledIcon = styled(Icon, {
  width: '$$iconSize',
  height: '$$iconSize',
  color: '$$iconColor',
});

export interface InputSharedProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  icon?: IconName;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
}

export interface BaseInputProps extends InputSharedProps {
  disabled?: boolean;
  invalid?: boolean;
  shouldApplyFocusStyles?: boolean;
  controlSize?: ControlSize;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { as?: React.ElementType };
  inputRef?: React.Ref<HTMLInputElement>;
  isNested?: boolean;
  type?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  css?: CSS;
}

export function BaseInput({
  disabled = false,
  invalid = false,
  shouldApplyFocusStyles = true,
  icon,
  isNested = false,
  prefix,
  suffix,
  width,
  maxWidth,
  minWidth,
  type,
  controlSize,
  inputProps,
  inputRef,
  ref,
  ...remaining
}: BaseInputProps & { ref?: React.Ref<HTMLLabelElement | null> }) {
  const size = useControlSize(controlSize, 'medium');
  const [isFocused, setIsFocused] = useState(false);
  const { focusProps } = useFocus({ onFocusChange: (value) => setIsFocused(value) });

  const [prefixContainerRef, { width: iconContainerWidth = 0 }] = useMeasure();
  const [suffixContainerRef, { width: sidecarContainerWidth = 0 }] = useMeasure();

  return (
    <BaseInputContainer
      ref={ref}
      role="presentation"
      size={size}
      type={type}
      invalid={invalid}
      isDisabled={disabled}
      isNested={isNested}
      data-is-focused={isFocused && shouldApplyFocusStyles ? '' : undefined}
      style={{ width, maxWidth, minWidth }}
      {...remaining}
    >
      {(icon || prefix) && (
        <ControlSizeProvider value="small">
          <Prefix ref={prefixContainerRef}>
            {icon && <StyledIcon icon={icon} />}
            {prefix}
          </Prefix>
        </ControlSizeProvider>
      )}
      <Input
        {...mergeProps(inputProps as object, focusProps)}
        ref={inputRef}
        style={{
          paddingLeft: iconContainerWidth > 0 ? iconContainerWidth : undefined,
          paddingRight: sidecarContainerWidth > 0 ? sidecarContainerWidth : undefined,
        }}
      />
      {suffix && (
        <ControlSizeProvider value="small">
          <Suffix ref={suffixContainerRef}>{suffix}</Suffix>
        </ControlSizeProvider>
      )}
    </BaseInputContainer>
  );
}

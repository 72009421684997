import type {
  DropdownMenuCheckboxItemProps as RadixDropdownMenuCheckboxItemProps,
  DropdownMenuContentProps as RadixDropdownMenuPopoverProps,
  DropdownMenuItemProps as RadixDropdownMenuItemProps,
  DropdownMenuRadioGroupProps as RadixDropdownMenuRadioGroupProps,
  DropdownMenuRadioItemProps as RadixDropdownMenuRadioItemProps,
} from '@radix-ui/react-dropdown-menu';
import type { RefObject } from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';

import type { DynamicIconName } from '../../assets/DynamicIcon/DynamicIcon';
import type { ButtonProps } from '../../controls/Button/Button';
import type { ShortcutKeys } from '../../formatting/Shortcut/Shortcut';
import type { SpacingScale } from '../../utilities/shared/sizes';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../common/animations';
import { zIndexes } from '../../common/z_indexes';
import { Button } from '../../controls/Button/Button';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { Small } from '../../text/Small';
import { Text } from '../../text/Text';
import { widthCSS } from '../../utilities/shared/Width';
import {
  AccessLevel,
  isAccessLevelVisible,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import { useViewport } from '../../utilities/useViewport';
import { Badge } from '../Badge/Badge';
import { Tooltip } from '../Tooltip/Tooltip';

export const DropdownMenuTrigger = RadixDropdownMenu.Trigger;

export function DropdownMenuButton({
  menuArrow = 'dropdown',
  size,
  variant = 'secondary',
  ref,
  ...remaining
}: ButtonProps & { ref?: React.Ref<HTMLButtonElement | null> }) {
  return (
    <RadixDropdownMenu.Trigger asChild>
      <Button {...remaining} ref={ref} size={size} variant={variant} menuArrow={menuArrow} />
    </RadixDropdownMenu.Trigger>
  );
}

const DropdownMenuItemIcon = styled(DynamicIcon, {
  color: '$$iconColor',

  '@notDesktop': {
    width: '$18',
    height: '$18',
  },

  '@desktop': {
    width: '$14',
    height: '$14',
  },

  variants: {
    internal: {
      true: {
        color: '$$internalIconColor',
      },
      false: {},
    },
  },
});

const DropdownMenuItemLabel = styled(Text, {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',

  '@notDesktop': {
    ...LargeSansSizes,
    gap: '$8',
  },

  '@desktop': {
    ...BodySansSizes,
    gap: '$6',
  },

  variants: {
    internal: {
      true: {
        color: '$$internalLabelColor',
      },
      false: {},
    },
  },
});

const dropdownMenuItemContainer = {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: '$$backgroundColor',
  cursor: 'pointer',
  $$iconColor: colors.iconNeutralLight,
  $$labelColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$iconColor: colors.iconNeutralDark,
    $$labelColor: colors.bodyNeutralDark,
  },

  '@notDesktop': {
    gap: '$8',
    padding: '$6 $10',
    borderRadius: '$10',
  },

  '@desktop': {
    gap: '$6',
    padding: '$4 $8',
    borderRadius: '$8',
  },

  '&[data-highlighted]': {
    zIndex: 3,
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    $$backgroundColor: colors.bgNeutralLight,
    $$iconColor: colors.bodyNeutralLight,
    $$labelColor: colors.headingNeutralLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
      $$backgroundColor: colors.bgNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
      $$labelColor: colors.headingNeutralDark,
    },
  },

  '&[aria-disabled="true"]': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  '&[aria-checked="true"]': {
    zIndex: 2,
    $$backgroundColor: colors.bgBrandLight,
    $$iconColor: colors.iconBrandLight,
    $$labelColor: colors.bodyBrandLight,

    [darkThemeSelector]: {
      $$backgroundColor: colors.bgBrandDark,
      $$iconColor: colors.iconBrandDark,
      $$labelColor: colors.bodyBrandDark,
    },

    '&[data-highlighted]': {
      $$backgroundColor: colors.bgBrandLight,
      $$iconColor: colors.bodyBrandLight,
      $$labelColor: colors.headingBrandLight,

      [darkThemeSelector]: {
        $$backgroundColor: colors.bgBrandDark,
        $$iconColor: colors.bodyBrandDark,
        $$labelColor: colors.headingBrandDark,
      },
    },
  },

  variants: {
    selected: {
      true: {
        zIndex: 2,
        $$backgroundColor: colors.bgBrandLight,
        $$iconColor: colors.iconBrandLight,
        $$labelColor: colors.bodyBrandLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgBrandDark,
          $$iconColor: colors.iconBrandDark,
          $$labelColor: colors.bodyBrandDark,
        },

        '&[data-highlighted]': {
          $$backgroundColor: colors.bgBrandLight,
          $$iconColor: colors.bodyBrandLight,
          $$labelColor: colors.headingBrandLight,

          [darkThemeSelector]: {
            $$backgroundColor: colors.bgBrandDark,
            $$iconColor: colors.bodyBrandDark,
            $$labelColor: colors.headingBrandDark,
          },
        },
      },
      false: {},
    },
    variant: {
      neutral: {},
      negative: {
        '&[data-highlighted]': {
          $$backgroundColor: colors.bgNegativeLight,
          $$iconColor: colors.bodyNegativeLight,
          $$labelColor: colors.headingNegativeLight,

          [darkThemeSelector]: {
            $$backgroundColor: colors.bgNegativeDark,
            $$iconColor: colors.bodyNegativeDark,
            $$labelColor: colors.headingNegativeDark,
          },
        },

        '&[aria-checked="true"]': {
          $$backgroundColor: colors.bgNegativeLight,
          $$iconColor: colors.iconNegativeLight,
          $$labelColor: colors.bodyNegativeLight,

          [darkThemeSelector]: {
            $$backgroundColor: colors.bgNegativeDark,
            $$iconColor: colors.iconNegativeDark,
            $$labelColor: colors.bodyNegativeDark,
          },

          '&[data-highlighted]': {
            $$backgroundColor: colors.bgNegativeLight,
            $$iconColor: colors.bodyNegativeLight,
            $$labelColor: colors.headingNegativeLight,

            [darkThemeSelector]: {
              $$backgroundColor: colors.bgNegativeDark,
              $$iconColor: colors.bodyNegativeDark,
              $$labelColor: colors.headingNegativeDark,
            },
          },
        },
      },
    },
    internal: {
      true: {
        $$internalIconColor: colors.internalIconLight,
        $$internalLabelColor: colors.internalBodyLight,

        [darkThemeSelector]: {
          $$internalIconColor: colors.internalIconDark,
          $$internalLabelColor: colors.internalBodyDark,
        },

        '&[aria-checked="true"], &[aria-checked="true"][data-highlighted]': {
          $$backgroundColor: colors.internalBgLight,
          $$internalIconColor: colors.internalBodyLight,
          $$internalLabelColor: colors.internalHeadingLight,

          [darkThemeSelector]: {
            $$backgroundColor: colors.internalBgDark,
            $$internalIconColor: colors.internalBodyDark,
            $$internalLabelColor: colors.internalHeadingDark,
          },
        },
      },
    },
  },
};

type DropdownMenuItemInnerProps = {
  accessLevel?: AccessLevel;
  annotation?: React.ReactNode;
  icon?: DynamicIconName;
  internal?: boolean;
  label?: React.ReactNode;
  selected?: boolean;
  tooltip?: React.ReactNode;
  shortcut?: ShortcutKeys[];
  variant?: 'neutral' | 'negative';
};

function DropdownMenuItemInner({
  accessLevel: accessLevelProp,
  annotation,
  icon,
  internal: internalProp,
  label,
  tooltip,
  selected,
  shortcut,
  variant,
}: DropdownMenuItemInnerProps) {
  const { breakpoint } = useViewport();
  const accessLevel = useAccessLevelContext(accessLevelProp);
  const internal = internalProp || accessLevel === AccessLevel.Internal;
  const labelContents = (
    <DropdownMenuItemLabel internal={internal} context="body" variant={variant}>
      {label}
    </DropdownMenuItemLabel>
  );
  return (
    <>
      {icon && <DropdownMenuItemIcon internal={internal} icon={icon} variant={variant} />}
      {label && (tooltip ? <Tooltip contents={tooltip}>{labelContents}</Tooltip> : labelContents)}
      {accessLevel && isAccessLevelVisible(accessLevel) && (
        <FeatureBadge size={breakpoint !== 'desktop' ? 'medium' : 'small'} type={accessLevel} />
      )}
      {annotation && (
        <Badge
          internal={internal}
          size={breakpoint !== 'desktop' ? 'medium' : 'small'}
          variant={selected ? 'brand' : variant}
        >
          {annotation}
        </Badge>
      )}
      {shortcut && (
        <Shortcut
          internal={internal}
          keys={shortcut}
          variant={variant === 'negative' ? 'destructive' : undefined}
        />
      )}
    </>
  );
}

export type DropdownMenuItemProps = RadixDropdownMenuItemProps & DropdownMenuItemInnerProps;

const DropdownMenuItemContainer = styled(RadixDropdownMenu.Item, {
  ...dropdownMenuItemContainer,
});

export function DropdownMenuItem({
  accessLevel: accessLevelProp,
  asChild,
  annotation,
  children,
  icon,
  internal: internalProp,
  label,
  ref,
  selected,
  shortcut,
  tooltip,
  variant,
  ...remaining
}: RadixDropdownMenuItemProps & DropdownMenuItemInnerProps & { ref?: React.Ref<HTMLDivElement> }) {
  const accessLevel = useAccessLevelContext(accessLevelProp);
  const internal = internalProp || accessLevel === AccessLevel.Internal;

  return (
    <DropdownMenuItemContainer
      internal={internal}
      ref={ref}
      {...remaining}
      asChild={asChild}
      selected={selected}
    >
      <DropdownMenuItemInner
        accessLevel={accessLevelProp}
        annotation={annotation}
        icon={icon}
        internal={internalProp}
        label={label || children}
        selected={selected}
        shortcut={shortcut}
        variant={variant}
        tooltip={tooltip}
      />
    </DropdownMenuItemContainer>
  );
}

const DropdownMenuCheckboxItemContainer = styled(RadixDropdownMenu.CheckboxItem, {
  ...dropdownMenuItemContainer,
});

export type DropdownCheckboxItemProps = RadixDropdownMenuCheckboxItemProps &
  DropdownMenuItemInnerProps & { ref?: RefObject<HTMLDivElement | null> };

export function DropdownMenuCheckboxItem({
  accessLevel: accessLevelProp,
  checked,
  children,
  icon,
  internal: internalProp,
  label,
  ref,
  shortcut,
  tooltip,
  ...remaining
}: DropdownCheckboxItemProps) {
  const [checkedState, setCheckedState] = useState(checked);
  const accessLevel = useAccessLevelContext(accessLevelProp);
  const internal = internalProp || accessLevel === AccessLevel.Internal;

  return (
    <DropdownMenuCheckboxItemContainer
      onCheckedChange={() => setCheckedState(!checkedState)}
      checked={checkedState}
      internal={internal}
      ref={ref}
      {...remaining}
    >
      <DropdownMenuItemInner
        accessLevel={accessLevelProp}
        icon={checkedState ? 'checkmark' : icon}
        internal={internalProp}
        label={label || children}
        shortcut={shortcut}
        tooltip={tooltip}
      />
    </DropdownMenuCheckboxItemContainer>
  );
}

const DropdownMenuRadioItemContainer = styled(RadixDropdownMenu.RadioItem, {
  ...dropdownMenuItemContainer,
});

export type DropdownRadioItemProps = RadixDropdownMenuRadioItemProps &
  DropdownMenuItemInnerProps & { ref?: RefObject<HTMLDivElement | null> };

export function DropdownMenuRadioItem({
  accessLevel: accessLevelProp,
  children,
  icon,
  internal: internalProp,
  label,
  ref,
  shortcut,
  tooltip,
  ...remaining
}: DropdownRadioItemProps) {
  const accessLevel = useAccessLevelContext(accessLevelProp);
  const internal = internalProp || accessLevel === AccessLevel.Internal;

  return (
    <DropdownMenuRadioItemContainer internal={internal} ref={ref} {...remaining}>
      <DropdownMenuItemInner
        accessLevel={accessLevelProp}
        icon={icon}
        internal={internalProp}
        label={label || children}
        shortcut={shortcut}
        tooltip={tooltip}
      />
    </DropdownMenuRadioItemContainer>
  );
}

const DropdownMenuLabel = styled(RadixDropdownMenu.Label, Small, {
  color: colors.controlContentPlaceholderLight,
  fontWeight: fontWeights.extraBold,

  '@notDesktop': {
    padding: '$6 $10',
  },

  '@desktop': {
    padding: '$4 $8',
  },

  [darkThemeSelector]: {
    color: colors.controlContentPlaceholderDark,
  },
});

const dropdownMenuGroupContainer = {
  display: 'flex',
  flexDirection: 'column',

  '@notDesktop': {
    padding: '$8',
  },

  '@desktop': {
    padding: '$6',
  },

  '&:not(:last-child)': {
    strokeBottom: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      strokeBottom: colors.strokeNeutralDark,
    },
  },
};

const DropdownMenuGroupContainer = styled(RadixDropdownMenu.Group, {
  ...dropdownMenuGroupContainer,
});

type DropdownMenuGroupProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
};

export function DropdownMenuGroup({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: React.ReactNode;
}) {
  return (
    <DropdownMenuGroupContainer>
      {label && <DropdownMenuLabel>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuGroupContainer>
  );
}

const DropdownMenuRadioGroupContainer = styled(RadixDropdownMenu.RadioGroup, {
  ...dropdownMenuGroupContainer,
});

export function DropdownMenuRadioGroup({
  children,
  label,
  ...remaining
}: RadixDropdownMenuRadioGroupProps & DropdownMenuGroupProps) {
  return (
    <DropdownMenuRadioGroupContainer {...remaining}>
      {label && <DropdownMenuLabel>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuRadioGroupContainer>
  );
}

const DropdownMenuPopoverContainer = styled(RadixDropdownMenu.Content, {
  zIndex: zIndexes.overlay,
  overflow: 'auto',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  minWidth: '$180',
  maxWidth: 'var(--radix-dropdown-menu-content-available-width)',
  maxHeight: 'var(--radix-dropdown-menu-content-available-height)',

  '@mobile': {
    width: 'var(--radix-dropdown-menu-content-available-width)',
  },

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',

    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideRightAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideLeftAndFade },
    },
  },

  variants: {
    matchTrigger: {
      true: {
        width: 'var(--radix-dropdown-menu-trigger-width)',
      },
      false: {},
    },
  },
});

export type DropdownMenuPopoverProps = RadixDropdownMenuPopoverProps & {
  container?: HTMLElement | null | undefined;
  /**
   * If true, will match the width of the trigger.
   * @default false
   */
  matchTrigger?: boolean;
  /**
   * If false, will render the contents in place instead of portalling to `document.body`.
   *
   * @default true
   */
  usePortal?: boolean;
  width?: SpacingScale;
  ref?: React.Ref<HTMLDivElement | null>;
};

export function DropdownMenuPopover({
  align = 'end',
  children,
  container,
  collisionPadding = 12,
  matchTrigger = false,
  side,
  sideOffset = 4,
  usePortal = true,
  width,
  ref: forwardedRef,
  ...props
}: DropdownMenuPopoverProps) {
  const { breakpoint } = useViewport();
  const defaultAlign = breakpoint !== 'mobile' ? 'end' : 'center';

  const contents = (
    <DropdownMenuPopoverContainer
      align={align || defaultAlign}
      collisionPadding={collisionPadding}
      matchTrigger={matchTrigger}
      side={side}
      sideOffset={sideOffset}
      css={widthCSS(width)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenuPopoverContainer>
  );

  return usePortal ? (
    <RadixDropdownMenu.Portal container={container}>{contents}</RadixDropdownMenu.Portal>
  ) : (
    contents
  );
}

export const DropdownMenu = RadixDropdownMenu.Root;

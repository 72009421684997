import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { ExecutableDefinitionNode } from 'graphql';
import type { ClientError, RequestDocument, Variables } from 'graphql-request';
import { generateHeaders } from '@meterup/common';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { graphqlClient } from '../client';
import { clientErrorToDisplayableError } from '../utils';

export type { TypedDocumentNode, Variables };

export function makeQueryKey<TResult, TVariables extends Variables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
) {
  return [(document.definitions[0] as ExecutableDefinitionNode)?.name?.value, variables];
}

export type UseGraphQLOptions<TResult> = Omit<
  UseQueryOptions<TResult, ClientError, TResult, any[]>,
  'initialData' | 'queryFn'
>;

export type UseGraphQLResult<TData> = UseQueryResult<TData, ClientError>;

export type UseGraphQLMutationResult<TData, TVariables extends Variables> = UseMutationResult<
  TData,
  ClientError,
  TVariables
>;

/**
 * Executes a GraphQL query with `@tanstack/react-query`'s `useQuery` hook with the graphQL query.
 * If you want to customize the behavior use the `options` argument.
 *
 * @template TResult - The type of the query result.
 * @template TVariables - The type of the query or mutation variables.
 *
 * @param {TypedDocumentNode<TResult, TVariables>} document - The GraphQL document representing the query or mutation.
 * @param {TVariables} variables - The variables to be passed to the query or mutation.
 * @param {UseGraphQLOptions} options - The options for the react-query query.
 *
 * @returns {UseQueryResult<TResult, ClientError>} - The result of the query, including fetching state, data, and errors.
 */
export function useGraphQL<TResult, TVariables extends Variables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  options: UseGraphQLOptions<TResult> = {},
): UseGraphQLResult<TResult> {
  return useQuery({
    suspense: true,
    queryKey: makeQueryKey<TResult, TVariables>(document, variables),
    ...options,
    queryFn: async () =>
      graphqlClient.request<TResult>(document, variables, generateHeaders()).catch((error) => {
        const displayableError: Error = clientErrorToDisplayableError(error);
        throw displayableError;
      }),
  });
}

export function useInfiniteGraphQL<TResult, TVariables extends Variables>(
  document: TypedDocumentNode<TResult, TVariables>,
  getVariables: ({ pageParam }: { pageParam?: number }) => TVariables,
  options?: UseInfiniteQueryOptions<TResult, Error>,
): UseInfiniteQueryResult<TResult, Error> {
  return useInfiniteQuery<TResult, Error>({
    suspense: true,
    queryKey: makeQueryKey<TResult, TVariables>(document, getVariables({})),
    ...options,
    queryFn: async ({ pageParam }) =>
      graphqlClient.request<TResult>(document, getVariables({ pageParam })).catch((error) => {
        const displayableError: Error = clientErrorToDisplayableError(error);
        throw displayableError;
      }),
  });
}

export function useGraphQLMutation<TData = unknown, TVariables extends Variables = Variables>(
  document: RequestDocument | TypedDocumentNode<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, unknown, TVariables>, 'mutationFn'>,
): UseGraphQLMutationResult<TData, TVariables> {
  return useMutation<TData, ClientError, TVariables>(
    async (variables: TVariables) =>
      graphqlClient.request<TData>(document, variables, generateHeaders()).catch((error) => {
        const displayableError: Error = clientErrorToDisplayableError(error);
        throw displayableError;
      }),
    options,
  );
}

/**
 * Imperative version of `useGraphQL`. Only use this if you know what you're doing.
 */
export async function getGraphQLQuery<TResult, TVariables extends Variables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
): Promise<TResult> {
  return graphqlClient.request<TResult>(document, variables, generateHeaders()).catch((error) => {
    const displayableError: Error = clientErrorToDisplayableError(error);
    throw displayableError;
  });
}

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type MaxHeightTypes = 'full' | 'screen' | SpacingScale;
export type MaxHeightProps = { maxHeight?: MaxHeightTypes };

export const maxHeightCSS = (maxHeight?: MaxHeightTypes): CSS => {
  if (maxHeight === 'full') {
    return { maxHeight: '100%' };
  }

  if (maxHeight === 'screen') {
    return { maxHeight: '100dvh' };
  }

  if (maxHeight != null) {
    return {
      maxHeight: `$${maxHeight}`,
    };
  }

  return {};
};

import { createContext, useContext } from 'react';

import type { MaxWidthTypes } from '../../utilities/shared/MaxWidth';
import type { MinWidthTypes } from '../../utilities/shared/MinWidth';
import type { WidthTypes } from '../../utilities/shared/Width';

export type ShellPropSize = 'x-small' | 'medium';

type ShellSize = ShellPropSize;
const ShellSizeContext = createContext<ShellSize | undefined>(undefined);
export const ShellSizeProvider = ShellSizeContext.Provider;
export const useShellSize = (controlledValue?: ShellSize, defaultValue: ShellSize = 'x-small') => {
  const shellSize = useContext(ShellSizeContext);
  return controlledValue ?? shellSize ?? defaultValue;
};

export type ShellPropLayoutMode = 'compact' | 'detailed';

type ShellLayoutMode = ShellPropLayoutMode;
const ShellLayoutModeContext = createContext<ShellLayoutMode | undefined>(undefined);
export const ShellLayoutModeProvider = ShellLayoutModeContext.Provider;
export const useShellLayoutMode = (
  controlledValue?: ShellLayoutMode,
  defaultValue: ShellLayoutMode = 'compact',
) => {
  const shellLayoutMode = useContext(ShellLayoutModeContext);
  return controlledValue ?? shellLayoutMode ?? defaultValue;
};

export type ShellPropContentMode = 'fit' | 'skimmer' | 'stretch';

type ShellContentMode = ShellPropContentMode;
const ShellContentModeContext = createContext<ShellContentMode | undefined>(undefined);
export const ShellContentModeProvider = ShellContentModeContext.Provider;
export const useShellContentMode = (
  controlledValue?: ShellContentMode,
  defaultValue: ShellContentMode = 'stretch',
) => {
  const shellContentMode = useContext(ShellContentModeContext);
  return controlledValue ?? shellContentMode ?? defaultValue;
};

export type ShellPropWidth = WidthTypes;

type ShellWidth = ShellPropWidth;
const ShellWidthContext = createContext<ShellWidth | undefined>(undefined);
export const ShellWidthProvider = ShellWidthContext.Provider;
export const useShellWidth = (controlledValue?: ShellWidth, defaultValue: ShellWidth = 'full') => {
  const shellWidth = useContext(ShellWidthContext);
  return controlledValue ?? shellWidth ?? defaultValue;
};

export type ShellPropWidthMode = 'centered' | 'full';

type ShellWidthMode = ShellPropWidthMode;
const ShellWidthModeContext = createContext<ShellWidthMode | undefined>(undefined);
export const ShellWidthModeProvider = ShellWidthModeContext.Provider;
export const useShellWidthMode = (
  controlledValue?: ShellWidthMode,
  defaultValue: ShellWidthMode = 'full',
) => {
  const shellWidthMode = useContext(ShellWidthModeContext);
  return controlledValue ?? shellWidthMode ?? defaultValue;
};

type ShellMaxWidth = MaxWidthTypes;
const ShellMaxWidthContext = createContext<ShellMaxWidth | undefined>(undefined);
export const ShellMaxWidthProvider = ShellMaxWidthContext.Provider;
export const useShellMaxWidth = (
  controlledValue?: ShellMaxWidth,
  defaultValue: ShellMaxWidth = 'full',
) => {
  const shellMaxWidth = useContext(ShellMaxWidthContext);
  return controlledValue ?? shellMaxWidth ?? defaultValue;
};

type ShellMinWidth = MinWidthTypes;
const ShellMinWidthContext = createContext<ShellMinWidth | undefined>(undefined);
export const ShellMinWidthProvider = ShellMinWidthContext.Provider;
export const useShellMinWidth = (
  controlledValue?: ShellMinWidth,
  defaultValue: ShellMinWidth = 'full',
) => {
  const shellMinWidth = useContext(ShellMinWidthContext);
  return controlledValue ?? shellMinWidth ?? defaultValue;
};
